import queryString from 'query-string';

export function parseFirstName(fullName?: string) {
  if (!fullName) return '';

  const firstName = fullName.match(/^[^ ]+/g);
  return firstName ? firstName[0] : '';
}

export function parseInitials(fullName?: string) {
  if (!fullName) return '😃';

  const array = fullName.match(/\b\w/g) || [];
  return ((array.shift() || '') + (array.pop() || '')).toUpperCase();
}

export async function parseFormData(request: Request) {
  const formQueryString = await request.text();
  const parsed = queryString.parse(formQueryString, {
    arrayFormat: 'bracket',
    parseBooleans: true,
    parseNumbers: true,
  });

  return Object.entries(parsed).reduce(
    (acc: Record<string, unknown>, [key, value]) => {
      if (value !== null && typeof value === 'object') {
        acc[key] = value.filter(Boolean);
      } else {
        acc[key] = value;
      }
      return acc;
    },
    {},
  );
}

export function parsePrice(price: number) {
  let stringified = `$${price.toLocaleString('da-DK')}`;

  if (price < 0) {
    stringified = stringified.replace('$-', '-$');
  }

  return stringified;
}
