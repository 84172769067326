import { tailwindConfig } from '@raffle-ai/design-system';

import type { CurveType } from 'recharts/types/shape/Curve';

export function getCartesianGridProps() {
  return {
    stroke: tailwindConfig.theme.colors.neutral[250],
    strokeDasharray: '3 3',
  };
}

export function getXAxisProps() {
  return {
    tickLine: false,
    axisLine: false,
    tick: {
      fill: tailwindConfig.theme.colors.neutral[450],
      fontSize: '12px',
      fontWeight: 400,
    },
  };
}

export function getYAxisProps() {
  return {
    allowDecimals: false,
    axisLine: false,
    tickLine: false,
    tickCount: 10,
    tick: {
      fill: tailwindConfig.theme.colors.neutral[450],
      fontSize: '12px',
      fontWeight: 400,
    },
    tickFormatter: (value: number) => {
      const formatter = Intl.NumberFormat('en', { notation: 'compact' });
      return formatter.format(value);
    },
  };
}

export function getLineProps(key: string, color: string) {
  return {
    dot: false,
    dataKey: key,
    type: 'monotone' as CurveType,
    stroke: color,
    strokeWidth: 1.5,
    strokeLinecap: 'round' as const,
    style: {
      filter: `drop-shadow(0px 0px 3px ${color})`,
    },
  };
}

export function getBarProps(color: string) {
  return {
    radius: [10, 10, 0, 0] as [number, number, number, number],
    fill: color,
    style: {
      filter: `drop-shadow(0px 0px 1px ${color})`,
    },
  };
}

export function getTooltipProps() {
  return {
    cursor: {
      stroke: tailwindConfig.theme.colors.neutral[100],
      strokeWidth: 25,
    },
    wrapperStyle: { outline: 'none' },
  };
}
