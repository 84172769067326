import { z } from 'zod';

import type { Answer, Source } from '~/models';

export const ExcludeOptionEnum = z.enum([
  'include',
  'exclude_page',
  'exclude_page_and_children',
  'index_no_follow',
  'follow_no_index',
]);

export type ExcludeOption = z.infer<typeof ExcludeOptionEnum>;

export const excludeOptions: Array<{
  label: string;
  value: ExcludeOption;
}> = [
  {
    label: 'Include this page',
    value: 'include',
  },
  {
    label: 'Exclude this page',
    value: 'exclude_page',
  },
  {
    label: 'Exclude this page and all children',
    value: 'exclude_page_and_children',
  },
  {
    label: "Index, but don't follow links",
    value: 'index_no_follow',
  },
  {
    label: "Follow links, but don't index",
    value: 'follow_no_index',
  },
];

function getRegex(
  excludeOption: ExcludeOption,
  externalSourceId: Answer['external_source_id'],
) {
  if (excludeOption === 'exclude_page_and_children') {
    return `^${externalSourceId}.*$`;
  }

  return `^${externalSourceId}$`;
}

export function inferExcludeOption(
  source: Source,
  externalSourceId: Answer['external_source_id'],
): ExcludeOption {
  if (source.type === 'web_scrape' && source.configuration) {
    if (
      source.configuration.disallowed_url_filters?.some(
        (filter) => filter === getRegex('exclude_page', externalSourceId),
      )
    ) {
      return 'exclude_page';
    }

    if (
      source.configuration.disallowed_url_filters?.some(
        (filter) =>
          filter === getRegex('exclude_page_and_children', externalSourceId),
      )
    ) {
      return 'exclude_page_and_children';
    }

    if (
      source.configuration.no_follow_url_filters?.some(
        (filter) => filter === getRegex('index_no_follow', externalSourceId),
      )
    ) {
      return 'index_no_follow';
    }

    if (
      source.configuration.no_index_url_filters?.some(
        (filter) => filter === getRegex('follow_no_index', externalSourceId),
      )
    ) {
      return 'follow_no_index';
    }
  }

  return 'include';
}

function excludeAnswer(
  source: Source,
  externalSourceId: Answer['external_source_id'],
  excludeOption: ExcludeOption,
) {
  if (source.type === 'web_scrape') {
    switch (excludeOption) {
      case 'exclude_page':
        source.configuration.disallowed_url_filters = [
          ...(source.configuration.disallowed_url_filters ?? []),
          getRegex('exclude_page', externalSourceId),
        ];
        break;

      case 'exclude_page_and_children':
        source.configuration.disallowed_url_filters = [
          ...(source.configuration.disallowed_url_filters ?? []),
          getRegex('exclude_page_and_children', externalSourceId),
        ];
        break;

      case 'index_no_follow':
        source.configuration.no_follow_url_filters = [
          ...(source.configuration.no_follow_url_filters ?? []),
          getRegex('index_no_follow', externalSourceId),
        ];
        break;

      case 'follow_no_index':
        source.configuration.no_index_url_filters = [
          ...(source.configuration.no_index_url_filters ?? []),
          getRegex('follow_no_index', externalSourceId),
        ];
        break;
    }
  }

  return source;
}

function includeAnswer(
  source: Source,
  externalSourceId: Answer['external_source_id'],
) {
  if (source.type === 'web_scrape') {
    source.configuration.disallowed_url_filters =
      source.configuration.disallowed_url_filters?.filter(
        (filter) => filter !== getRegex('exclude_page', externalSourceId),
      );

    source.configuration.disallowed_url_filters =
      source.configuration.disallowed_url_filters?.filter(
        (filter) =>
          filter !== getRegex('exclude_page_and_children', externalSourceId),
      );

    source.configuration.no_follow_url_filters =
      source.configuration.no_follow_url_filters?.filter(
        (filter) => filter !== getRegex('index_no_follow', externalSourceId),
      );

    source.configuration.no_index_url_filters =
      source.configuration.no_index_url_filters?.filter(
        (filter) => filter !== getRegex('follow_no_index', externalSourceId),
      );
  }

  return source;
}

export function updateExcludeOption(
  source: Source,
  externalSourceId: Answer['external_source_id'],
  excludeOption: ExcludeOption,
) {
  const cleanSource = includeAnswer(source, externalSourceId);

  if (excludeOption === 'include') {
    return cleanSource;
  }

  return excludeAnswer(cleanSource, externalSourceId, excludeOption);
}
