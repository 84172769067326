// Used to shift the array values for 'incoming inquiries chart' to align with local time
// - we get the hours by UTC and we 'shift' the values to local time

type ArrayItemToShift = {
  hour: number;
};

export default function arrayShiftByOffset<T extends ArrayItemToShift>(
  arr: T[],
  offset: number,
) {
  if (offset > 0) {
    const updated = arr.map((item) => ({
      ...item,
      hour: item.hour + offset,
    }));
    const toBeMovedToBeginning = updated.slice(arr.length - offset).reverse();
    updated.splice(updated.length - offset);
    toBeMovedToBeginning.forEach((item) =>
      updated.unshift({ ...item, hour: updated[0].hour - 1 }),
    );
    return updated;
  } else if (offset < 0) {
    const updated = arr.map((item) => ({
      ...item,
      hour: item.hour + offset,
    }));
    const toBeMovedToEnd = updated.slice(0, Math.abs(offset));
    updated.splice(0, Math.abs(offset));
    toBeMovedToEnd.forEach((item) =>
      updated.push({ ...item, hour: updated[updated.length - 1].hour + 1 }),
    );
    return updated;
  }
  return arr;
}
