export function capitalize(text: string) {
  return text.charAt(0).toUpperCase() + text.slice(1);
}
export function correctURL(str: string) {
  if (str.includes('https://') || str.includes('http://')) {
    return str;
  } else {
    return `https://${str}`;
  }
}
